





























































































import { Component, Vue } from 'vue-property-decorator';
import { loadView } from '@/utils/helpers';
import SideBarDrawer from '@/views/SideBarDrawer.vue';
import { Getter, namespace } from 'vuex-class';
import { ISideBar, IUser } from '@/types/types';

const authModule = namespace('auth');
const navigationModule = namespace('navigation');
@Component({
  components: {
    SideBarDrawer,
    'app--bar': loadView(`NavBar`),
    'app--drawer': loadView(`SideBar`),
  },
})
export default class Master extends Vue {
  @Getter('getNavigationDrawerState') drawerState!: boolean;
  @authModule.Getter('getUserType') userType!: string;
  @authModule.Getter('getUserDetails') user!: IUser;
  @navigationModule.Getter('getNavigationLinks') links!: Array<ISideBar | { label: string }>;
  @navigationModule.Getter('getNavigationKey') navigationKey!: string;
  state: boolean = true;
  menu: boolean = false;

  emitDrawer(value: boolean) {
    this.state = value;
  }

  closeDrawer(state: boolean) {
    this.$store.dispatch('navigationDrawer', state, { root: true });
  }
  signOut() {
    this.$store.dispatch('auth/logOut', null);
  }

  created(): void {
    this.$store.dispatch('navigation/setHomeLinks', 'home');
    this.$store.dispatch('auth/me');
  }
}
